import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Container,
  Row,
  Button,
} from "reactstrap";
import * as FlexmonsterReact from "react-flexmonster";
import moment from "moment";
import { utilsHelper } from "../../../helpers/utilsHelper";
import { inspectionsApi } from "../../../services/inspectionServices";
import WeekSelector from "../../../components/admin/WeekSelector";
import { reportsHelper } from "../../../helpers/reportsHelper";
import Loader from "../../../components/Loader";
import { useNavigate } from "react-router";
import InspectionDetails from "../../../components/admin/workOrderDetails/inspections/InspectionDetails";
import CustomPeriodSelector from "../../../components/admin/CustomPeriodSelector";
import PeriodTypeSelector from "../../../components/admin/PeriodTypeSelector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import InformationModal from "../../../components/InformationModal";
import ConfirmationModal from "../../../components/ConfirmationModal";

const REPORT_NAME = "Parts_";
const NAME_COLUMN = "Work Order #";

const columnLabel = [
  "Customer Name",
  "Employee",
  "Work Order #",
  "Sales Order",
  "Quote Number",
  "Inspection Name",
  "Equipment",
  "Completed Date",
  "Sales Rep Name",
  "Potential Parts Order",
  "Actions",
];

const COLUMNS = columnLabel.reduce((acc, label) => {
  acc[label] = {
    type: "string",
  };

  return acc;
}, {});

const report = {
  formats: [
    {
      name: "",
      thousandsSeparator: ",",
      decimalPlaces: 2,
    },
  ],
  dataSource: {
    data: [COLUMNS],
  },
  slice: {
    rows: columnLabel.map((label) => ({ uniqueName: label })),
    expands: {
      expandAll: true,
    },
  },
  options: {
    configuratorActive: false,
    grid: {
      type: "flat",
      showTotals: "off",
      showGrandTotals: "off",
      title: "Parts Report",
    },
  },
};

const TYPE_WEEKLY = "TYPE_WEEKLY";

const initModal = {
  isOpen: false,
  onSubmit: null,
  onClose: null,
  title: "",
  body: "",
};

const PartsReport = () => {
  const navigate = useNavigate();
  const pivot = useRef(null);

  const [data, setData] = useState(null);

  const getFilename = () =>
    reportsHelper.getFileName(monday, true, REPORT_NAME);

  const [informationModal, setInformationModal] = useState(initModal);

  const [confirmationModal, setConfirmationModal] = useState(initModal);

  const [monday, setMonday] = useState(moment().startOf("isoWeek"));

  const [periodType, setPeriodType] = useState({
    value: TYPE_WEEKLY,
    label: "Weekly",
  });

  const [loading, setLoading] = useState(false);
  const [inspectionDetailModal, setInspectionDetailModal] = useState(undefined);

  const [customStartDate, setCustomStartDate] = useState(
    moment().startOf("isoWeek")
  );
  const [customEndDate, setCustomEndDate] = useState(moment().endOf("isoWeek"));

  const addButtonToCells = useCallback((cell, entry) => {
    if (
      entry.type !== "header" &&
      entry.measure?.uniqueName === "Actions" &&
      entry.rowIndex > 0
    ) {
      cell.text = `<input 
            class="btn btn-info text-white rounded text-center col-12"
            type="button"
            value="Delete"
          >`;
      cell.style["z-index"] = 1;
    }
  }, []);

  const fetchData = useCallback(() => {
    setLoading(true);

    const { value } = periodType;
    const condition = {
      startDate:
        value === TYPE_WEEKLY
          ? moment(monday).format("YYYY-MM-DD")
          : moment(customStartDate).format("YYYY-MM-DD"),
      endDate:
        value === TYPE_WEEKLY
          ? moment(monday).endOf("isoWeek").format("YYYY-MM-DD")
          : moment(customEndDate).format("YYYY-MM-DD"),
    };

    inspectionsApi
      .getInspectionSaleItems(condition)
      .then((result) => {
        const entries = result
          .map((entry) => {
            return entry.items.map((item, index) => {
              if (index === 0) {
                const workOrderData =
                  entry.inspectionAnswer.inspection.inspectionTypeWorkOrder
                    .workOrder;
                return {
                  ...entry,
                  "Customer Name": workOrderData.customerName ?? "-",
                  Employee: `${entry.inspectionAnswer.inspection.user.firstName} ${entry.inspectionAnswer.inspection.user.lastName}`,
                  "Work Order #": workOrderData.workOrderNumber || "-",
                  "Sales Order": workOrderData.saleOrderNumber || "-",
                  "Quote Number": workOrderData.quoteNumber || "-",
                  "Inspection Name":
                    entry.inspectionAnswer.inspection.inspectionTypeWorkOrder
                      .inspectionType.name || "-",
                  Equipment:
                    entry.inspectionAnswer.inspection.inspectionTypeWorkOrder
                      .equipment,
                  "Completed Date":
                    utilsHelper.formatUTCDate(
                      entry.inspectionAnswer.inspection.date,
                      "MM/DD/YYYY"
                    ) || "-",
                  "Sales Rep Name":
                    workOrderData.sales?.[0]?.salesRepName || "-",
                  "Potential Parts Order": item,
                  Actions: item,
                };
              }

              return {
                ...entry,
                "Customer Name": "-",
                Employee: "-",
                "Work Order #": "-",
                "Sales Order": "-",
                "Quote Number": "-",
                "Inspection Name": "-",
                Equipment: "-",
                "Completed Date": "-",
                "Sales Rep Name": "-",
                "Potential Parts Order": item,
                Actions: item,
              };
            });
          })
          .flat();

        setData(entries);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [periodType, monday, customStartDate, customEndDate]);

  const onButtonClick = useCallback(
    (cell) => {
      if (cell.hierarchy && cell.hierarchy.caption === "Actions") {
        const salesItem = data[cell.rowIndex - 1];
        if (!salesItem) return;

        setConfirmationModal({
          isOpen: true,
          confirmColor: "danger",
          onSubmit: async () => {
            try {
              setLoading(true);
              setConfirmationModal(initModal);

              const items = salesItem.items.filter(
                (item) => item !== cell?.label
              );

              if (items.length && items.length >= 1) {
                await inspectionsApi.updateInspectionSaleItem({
                  id: salesItem.id,
                  items,
                });
              } else {
                await inspectionsApi.deleteInspectionSaleItem(salesItem);
              }
              fetchData();
              setInformationModal({
                onClose: () => setInformationModal(initModal),
                isOpen: true,
                title: "Remove Sales Report Item",
                body: "Item removed successfully",
              });
            } catch (err) {
              setInformationModal({
                isOpen: true,
                title: "Remove Sales Report Item",
                onClose: () => setInformationModal(initModal),
                body:
                  err?.response?.data[0]?.msg ||
                  "There was an error with your request.",
              });
            } finally {
              setLoading(false);
            }
          },
          onClose: () => {
            setConfirmationModal(initModal);
          },
          title: "Remove Sales Report Item",
          body: `Are you sure you want to delete this item?`,
        });
      }
    },
    [data, fetchData]
  );

  useEffect(() => {
    if (pivot.current) {
      pivot.current.flexmonster.updateData({
        data: [COLUMNS, ...(data || [])],
      });

      if (data?.length) {
        // Clear all existing event listeners
        pivot.current.flexmonster.off("cellclick");

        // Add cell customization and single event listener
        pivot.current.flexmonster.customizeCell(addButtonToCells);
        pivot.current.flexmonster.on("cellclick", onButtonClick);
      }
    }
  }, [addButtonToCells, data, onButtonClick]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (pivot?.current?.flexmonster && data?.length) {
      pivot.current.flexmonster.on("celldoubleclick", function (cell) {
        if (
          cell.hierarchy?.uniqueName === "Inspection Name" &&
          cell.label !== "(blank)" &&
          cell.label !== "-"
        ) {
          const inspectionId =
            data &&
            cell.rowIndex - 1 < data.length &&
            data[cell.rowIndex - 1].inspectionAnswer.inspection.id;
          if (inspectionId) {
            setInspectionDetailModal(inspectionId);
          }
        } else {
          reportsHelper.navigateToWorkOrder(
            cell,
            data,
            NAME_COLUMN,
            null,
            navigate
          );
        }
      });
    }
  }, [pivot, data, navigate]);

  const copyAllToClipboard = async () => {
    try {
      const text = data.map((item) => item["Potential Parts Order"]);

      await navigator.clipboard.writeText(text.join("\n"));
    } catch (err) {
      setInformationModal({
        isOpen: true,
        title: "Copy All Parts Order",
        body: "There was an error copying the parts order to the clipboard",
        onClose: () => setInformationModal({ isOpen: false }),
      });
    }
  };

  return (
    <Container fluid className="d-flex flex-column flex-grow-1">
      <Row className="flex-grow-1">
        <Col className="d-flex flex-column">
          <Card className="flex-grow-1">
            <CardHeader className="d-flex align-items-center justify-content-end">
              {loading ? (
                <div className="min-width-50">
                  <Loader size="sm" className="mr-3" />
                </div>
              ) : null}
              {data && data.length > 0 ? (
                <Button onClick={copyAllToClipboard} className="text-sm py-2">
                  <FontAwesomeIcon className="mr-2" icon={faCopy} />
                  Copy All Parts Order
                </Button>
              ) : null}
              <PeriodTypeSelector
                periodType={periodType}
                setPeriodType={setPeriodType}
              />
              {periodType.value === TYPE_WEEKLY ? (
                <WeekSelector
                  loading={loading}
                  monday={monday}
                  setMonday={setMonday}
                />
              ) : (
                <CustomPeriodSelector
                  defaultEndDate={customEndDate}
                  defaultStartDate={customStartDate}
                  onSubmit={(startDate, endDate) => {
                    setCustomStartDate(startDate);
                    setCustomEndDate(endDate);
                  }}
                />
              )}
            </CardHeader>
            <CardBody className="d-flex flex-column flex-grow-1">
              <div className="rounded border height-100">
                <FlexmonsterReact.Pivot
                  ref={pivot}
                  licenseKey={process.env.REACT_APP_FLEX_M_KEY}
                  toolbar={true}
                  height="100%"
                  report={report}
                  beforetoolbarcreated={(toolbar) =>
                    utilsHelper.customizeToolbarReport(
                      toolbar,
                      pivot,
                      getFilename()
                    )
                  }
                />
              </div>
            </CardBody>
            {inspectionDetailModal ? (
              <InspectionDetails
                inspectionId={inspectionDetailModal}
                onClose={() => {
                  setInspectionDetailModal(undefined);
                  fetchData();
                }}
              />
            ) : null}
          </Card>
        </Col>
      </Row>
      {confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          rawBody={informationModal.rawBody}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </Container>
  );
};

export default PartsReport;
