import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import ReactPaginate from "react-paginate";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const PaginationSelector = ({
  pageCount,
  currentPage,
  onPageChange,
  pageSize,
  onPageSizeChange,
  totalCount,
  rowsPerPageOptions = [100, 200, 500, 1000],
  className = "",
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <div className={`d-flex justify-content-between  h-100 ${className}`}>
      <Dropdown
        isOpen={dropdownOpen}
        toggle={toggle}
        style={{ margin: "1px 0 1px 0" }}
      >
        <DropdownToggle caret className="h-100">
          {pageSize === totalCount ? "All" : pageSize}
        </DropdownToggle>
        <DropdownMenu>
          {rowsPerPageOptions.map((value) => (
            <DropdownItem
              key={value}
              className="font-weight-normal"
              onClick={() => onPageSizeChange(Number(value))}
            >
              {value}
            </DropdownItem>
          ))}
          <DropdownItem
            className="font-weight-normal"
            onClick={() => onPageSizeChange(totalCount)}
          >
            All
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <ReactPaginate
        forcePage={currentPage}
        previousLabel={<FontAwesomeIcon icon={faChevronLeft} />}
        nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
        breakLabel={"..."}
        pageCount={pageCount || currentPage + 1}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={(event) => onPageChange(event.selected + 1)}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        containerClassName={
          "ml-2 cursor-pointer pagination d-flex justify-content-center align-items-center mb-0 h-100"
        }
        pageClassName={"page-item h-100"}
        pageLinkClassName={
          "page-link h-100 d-flex justify-content-center align-items-center"
        }
        previousClassName={"page-item h-100"}
        previousLinkClassName={
          "page-link h-100 d-flex justify-content-center align-items-center"
        }
        nextClassName={"page-item h-100"}
        nextLinkClassName={
          "page-link h-100 d-flex justify-content-center align-items-center"
        }
        activeClassName={"active"}
      />
    </div>
  );
};
export default PaginationSelector;
