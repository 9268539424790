import {
  faCar,
  faExclamationTriangle,
  faPen,
  faStickyNote,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useState } from "react";
import { Badge, Button } from "reactstrap";
import {
  ACTIONS,
  useWorkOrderDetails,
} from "../../../providers/workOrderDetailsProvider";
import EmployeeNotesModal from "../../EmployeeNotesModal";
import InformationModal from "../../InformationModal";

import CrewMemberScheduleModal from "../CrewMemberScheduleModal";
import { useAuth } from "../../../providers/authProvider";
import { utilsHelper } from "../../../helpers/utilsHelper";
import SafetyCertificationsModal from "../../SafetyCertificationsModal";

const STATUS_INVOICED = 6;

const EmployeesWorkDays = ({ crew, employeeWorkDays }) => {
  const [authContext] = useAuth();
  const [workOrderDetails, setWorkOrderDetails] = useWorkOrderDetails();

  const truckDriverRole = authContext.currentUser.roleOptions.find(
    (role) => role.name === "Truck Driver"
  );

  const [safetyCertificationsModal, setSafetyCertificationsModal] = useState({
    isOpen: false,
    employee: null,
  });

  const [employeeNotesModal, setEmployeeNotesModal] = useState({
    isOpen: false,
    employeeId: null,
  });

  const [editScheduleModal, setEditScheduleModal] = useState({
    isOpen: false,
    employeeWorkDay: null,
    employee: null,
    employeeCrew: null,
  });

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const onEditSchedule = (employee, e, employeeCrew) =>
    setEditScheduleModal({
      isOpen: true,
      employeeWorkDay: e,
      employee,
      employeeCrew,
    });

  const onEditNotes = (employee) => {
    setEmployeeNotesModal({
      isOpen: true,
      employeeId: employee.id,
    });
  };

  const onScheduleSubmit = () => {
    setWorkOrderDetails({
      action: ACTIONS.REFRESH,
    });
    setEditScheduleModal({
      isOpen: false,
      employeeWorkDay: null,
      employee: null,
      employeeCrew: null,
    });
    return setInformationModal({
      isOpen: true,
      title: "Edit Employee Schedule",
      body: `Employee Schedule edited successfully`,
    });
  };

  const onSafetyDocs = (employee) => {
    setSafetyCertificationsModal({
      isOpen: true,
      employee,
    });
  };

  return (
    <>
      {utilsHelper
        .sortCrew(
          crew.employeeCrews.filter((ec) =>
            employeeWorkDays.find(
              (ewd) => ec.employee.id === ewd.employeeId && !ec.disabledAt
            )
          )
        )
        .map((employeeCrew) => {
          const ewd = employeeWorkDays.find(
            (ewd) =>
              employeeCrew.employee.id === ewd.employeeId &&
              !employeeCrew.disabledAt
          );
          employeeCrew.crewId = crew.id;
          const employee = employeeCrew?.employee;
          const hasTruckDriverRole = [
            employeeCrew.employee.role,
            employeeCrew.role,
          ].some((role) => role?.id === truckDriverRole?.id);

          const currentEndDate = workOrderDetails.workOrder.endDate
            ? moment.min(
                moment(ewd.endDate),
                moment(workOrderDetails.workOrder.endDate)
              )
            : moment(ewd.endDate);

          const currentStartDate = workOrderDetails.workOrder.startDate
            ? moment.max(
                moment(ewd.startDate),
                moment(workOrderDetails.workOrder.startDate)
              )
            : moment(ewd.startDate);

          const currentDate = {
            startDate: currentStartDate,
            endDate: currentEndDate,
            ...ewd,
          };

          return (
            <tr key={ewd.employeeId}>
              <td className="d-flex justify-content-between align-items-center">
                <div>
                  <div>
                    {employee?.firstName} {employee?.lastName}
                  </div>
                  <small className="text-muted">
                    {moment(currentDate.startDate).format("l")} to{" "}
                    {moment(currentDate.endDate).format("l")}
                  </small>
                </div>
                <div className="d-flex">
                  {hasTruckDriverRole ? (
                    <Badge
                      color={
                        employeeCrew.disabledCustomPerDiemRules
                          ? "danger"
                          : "success"
                      }
                      className="d-flex align-items-center px-2"
                    >
                      <FontAwesomeIcon icon={faCar} className="mr-2" />
                      <span>
                        Truck Driver{" "}
                        {employeeCrew.disabledCustomPerDiemRules
                          ? "Disabled"
                          : "Enabled"}
                      </span>
                    </Badge>
                  ) : null}
                  {workOrderDetails.workOrder.statusId !== STATUS_INVOICED ? (
                    <>
                      {employeeCrew.isVanDriver ? (
                        <Badge
                          color="tertiary"
                          className="d-flex align-items-center px-2"
                        >
                          <FontAwesomeIcon icon={faCar} className="mr-2" />
                          <span>Van Driver</span>
                        </Badge>
                      ) : null}
                      {!utilsHelper.isReadOnly(authContext) ? (
                        <>
                          <Button
                            color="seven"
                            size="sm"
                            className="rounded d-flex align-items-center mx-2"
                            onClick={() => onSafetyDocs(employee)}
                          >
                            <FontAwesomeIcon
                              icon={faExclamationTriangle}
                              className="mr-2"
                            />
                            <span>Safety Docs</span>
                          </Button>
                          <Button
                            color="info"
                            size="sm"
                            className="rounded d-flex align-items-center mx-2"
                            onClick={() => onEditNotes(employee, ewd)}
                          >
                            <FontAwesomeIcon
                              icon={faStickyNote}
                              className="mr-2"
                            />
                            <span>Notes</span>
                          </Button>
                          <Button
                            color="warning"
                            size="sm"
                            className="rounded d-flex align-items-center"
                            onClick={() =>
                              onEditSchedule(
                                employee,
                                currentDate,
                                employeeCrew
                              )
                            }
                          >
                            <FontAwesomeIcon icon={faPen} className="mr-2" />
                            <span>Edit</span>
                          </Button>
                        </>
                      ) : null}
                    </>
                  ) : null}
                </div>
              </td>
            </tr>
          );
        })}
      {employeeNotesModal.isOpen ? (
        <EmployeeNotesModal
          employeeId={employeeNotesModal.employeeId}
          onClose={() =>
            setEmployeeNotesModal({
              isOpen: false,
            })
          }
        />
      ) : informationModal.isOpen ? (
        <InformationModal
          {...informationModal}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : editScheduleModal.isOpen ? (
        <CrewMemberScheduleModal
          employee={editScheduleModal.employee}
          employeeWorkDay={editScheduleModal.employeeWorkDay}
          employeeCrew={editScheduleModal.employeeCrew}
          onClose={() =>
            setEditScheduleModal({
              isOpen: false,
              employeeWorkDay: null,
              employee: null,
              employeeCrew: null,
            })
          }
          onSubmit={onScheduleSubmit}
        />
      ) : safetyCertificationsModal.isOpen ? (
        <SafetyCertificationsModal
          employee={safetyCertificationsModal.employee}
          onClose={() => setSafetyCertificationsModal({ isOpen: false })}
        />
      ) : null}
    </>
  );
};
export default EmployeesWorkDays;
