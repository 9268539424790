import React, { useState } from "react";

import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from "reactstrap";
import { pdfjs, Document, Page } from "react-pdf";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ADDITIONAL_CONTENT_PLACEMENT = {
  HEADER: "HEADER",
  BODY_TOP: "BODY_TOP",
  BODY_BOTTOM: "BODY_BOTTOM",
};

const DocumentPreviewModal = ({
  onClose,
  file,
  pageWidth,
  additionalContent = {},
}) => {
  const {
    placement = ADDITIONAL_CONTENT_PLACEMENT.BODY_BOTTOM,
    content = null,
  } = additionalContent;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return (
    <Modal isOpen={true} onClosed={onClose} size="lg">
      <ModalHeader close={closeBtn}>
        Document Preview{" "}
        {placement === ADDITIONAL_CONTENT_PLACEMENT.HEADER && content
          ? content
          : null}
      </ModalHeader>
      <ModalBody className="p-0 text-center">
        {placement === ADDITIONAL_CONTENT_PLACEMENT.BODY_TOP && content
          ? content
          : null}
        <Document
          className="d-flex justify-content-center"
          file={file}
          onLoadSuccess={({ numPages }) => setNumPages(numPages)}
        >
          <Page width={pageWidth} pageNumber={pageNumber} />
        </Document>
        <div className="d-flex justify-content-between p-3 align-items-center text-body">
          <FontAwesomeIcon
            className="cursor-pointer text-body"
            icon={faChevronLeft}
            onClick={() => setPageNumber(Math.max(pageNumber - 1, 1))}
          />
          <span>
            Page {pageNumber} of {numPages}
          </span>
          <FontAwesomeIcon
            className="cursor-pointer text-body"
            icon={faChevronRight}
            onClick={() => setPageNumber(Math.min(pageNumber + 1, numPages))}
          />
        </div>
        {placement === ADDITIONAL_CONTENT_PLACEMENT.BODY_BOTTOM && content
          ? content
          : null}
      </ModalBody>
      <ModalFooter className="justify-content-start">
        <Button color={"secondary"} onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DocumentPreviewModal;
