import { useState } from "react";
import ReactQuill from "react-quill";
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import InformationModal from "./InformationModal";

const EmailRecipientsModal = ({
  onClose,
  onSubmit,
  title = "Select email recipients",
  defaultContent = "",
}) => {
  const [recipients, setRecipients] = useState("");
  const [content, setContent] = useState(defaultContent || "");

  const [loading, setLoading] = useState(false);

  const [informationModal, setInformationModal] = useState({
    rawBody: false,
    isOpen: false,
    title: "",
    body: "",
    onClose: null,
  });

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const emailList = recipients.split(",").map((email) => email.trim());
      await onSubmit(emailList, content);
    } catch (error) {
      setInformationModal({
        isOpen: true,
        title: "Send Certificate by Email",
        body: "There was an error sending the email.",
        onClose: () => setInformationModal({ isOpen: false }),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal isOpen={true} toggle={onClose} size="md">
        <ModalHeader toggle={onClose}>{title}</ModalHeader>
        <ModalBody>
          <Row className="mb-3 d-flex align-items-center">
            <Col sm="4" className="d-flex flex-column">
              <Label className="mb-0" for="subject">
                Recipients
              </Label>
              <small className="text-primary">Separated by commas</small>
            </Col>
            <Col sm="8">
              <Input
                maxLength="1024"
                type="text"
                placeholder="Input recipients"
                value={recipients}
                onChange={(e) => setRecipients(e.target.value)}
                required
              />
            </Col>
          </Row>
          <div className="mt-3">
            <Col sm="12 border rounded">
              <ReactQuill
                placeholder="Type something..."
                theme={"snow"}
                value={content}
                onChange={(content) => setContent(content)}
              />
            </Col>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onClose} disabled={loading}>
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={handleSubmit}
            disabled={!recipients.trim() || loading}
          >
            {loading ? "Sending..." : "Send"}
          </Button>
        </ModalFooter>
      </Modal>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          rawBody={informationModal.rawBody}
          onClose={informationModal.onClose}
        />
      ) : null}
    </>
  );
};

export default EmailRecipientsModal;
