import React from "react";
import { useMemo } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const style = {
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: "none",
    minWidth: "125px",
  }),
};

const animatedComponents = makeAnimated();

const TYPE_WEEKLY = "TYPE_WEEKLY";
const TYPE_CUSTOM_PERIOD = "TYPE_CUSTOM_PERIOD";

const PeriodTypeSelector = ({
  periodType,
  setPeriodType,
  isDisabled,
  additionalOptions = [],
}) => {
  const options = useMemo(() => {
    const baseOptions = [
      {
        label: "Date Range",
        value: TYPE_CUSTOM_PERIOD,
      },
      {
        label: "Weekly",
        value: TYPE_WEEKLY,
      },
    ];
    if (!additionalOptions.length) return baseOptions;

    return [...baseOptions, ...additionalOptions];
  }, [additionalOptions]);

  return (
    <div className="d-flex ml-3">
      <Select
        styles={style}
        className="min-width-150 flex-grow-1 border rounded small"
        options={options}
        closeMenuOnSelect={true}
        components={animatedComponents}
        value={periodType}
        onChange={setPeriodType}
        isDisabled={isDisabled}
      />
    </div>
  );
};

export default PeriodTypeSelector;
